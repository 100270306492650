
import { formatLocalTime } from '@/utils/format-time';
import { get } from 'lodash';
import { defineComponent, ref, toRefs, watch } from 'vue';
import draggable from 'vuedraggable';

export default defineComponent({
  components: {
    draggable
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const { data } = toRefs(props);

    const list = ref();

    watch(data, () => {
      list.value = data.value;
    }, {
      immediate: true
    });

    watch(list, () => {
      emit('change', list.value);
    });

    return {
      get,
      list,
      formatLocalTime
    };
  }
});
