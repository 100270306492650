import { SortOrder } from '@/services/api';
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function useSort<TSortKey extends string = string>() {
  const router = useRouter();
  const route = useRoute();

  const sortOrder = ref<SortOrder>();
  const sortKey = ref<TSortKey>();

  watch([sortOrder, sortKey], ([sort, sortKey]) => {
    router.push({ query: { ...route.query, sort, sortKey } });
  });

  watch(
    () => route.query,
    (query) => {
      sortOrder.value = query.sort as SortOrder;
      sortKey.value = query.sortKey as TSortKey;
    }, { immediate: true });

  return {
    sortOrder,
    sortKey
  };
}
