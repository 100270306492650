
import { computed, defineComponent, ref, toRefs, watch } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    checkboxTitle: {
      type: Boolean,
      default: false
    },
    checkboxModel: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:checkboxModel'],
  setup(props, { emit }) {
    const { checkboxModel } = toRefs(props);

    const syncCheckboxModel = computed({
      get() {
        return checkboxModel.value;
      },
      set(newValue) {
        emit('update:checkboxModel', newValue);
      }
    });

    return {
      syncCheckboxModel
    };
  }
});
