import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function useKeyword(initialKeyword = '') {
  const router = useRouter();
  const route = useRoute();

  const keyword = ref<string>((route.query.keyword || initialKeyword) as string);

  // cache 搜索條件在 queryString
  watch(keyword, (newKeyword) => {
    router.push({ query: { ...route.query, keyword: newKeyword } });
  });

  return { keyword };
}
