
import { PropType, defineComponent, reactive, ref, toRefs, computed, watch } from 'vue';
import SortableSectionTable from './SortableSectionTable.vue';
import SectionLayout from '@/components/SectionLayout.vue';
import Tinymce from '@/components/tinymce/Index.vue';
import { cloneDeep, filter, get, isEmpty, pick } from 'lodash';
import { ElMessage, ElForm, ElMessageBox } from 'element-plus';
import dayjs from 'dayjs';
import { CreateEventData, EventSection, ResponseError } from '@/services/api';
import { ELinkType } from '@/interfaces';
import { useCreateEvent, useUpdateEvent, useEvent } from '@/composables';
import { useRoute, useRouter } from 'vue-router';

type Mode = 'create' | 'edit';

const DEFAULT_FORM: CreateEventData = {
  startedAt: null,
  enrollEndedAt: null,
  endedAt: null,
  name: null,
  slug: null,
  seoTitle: null,
  seoKeywords: null,
  seoDescription: null,
  emailNotification: null,
  eventSections: []
};

const rules = {
  name: [
    { required: true, message: '請輸入活動名稱', trigger: 'blur' }
  ],
  slug: [
    { required: true, message: '請輸入活動連結', trigger: 'blur' },
    { pattern: /^[A-Za-z0-9_.-]*$/, message: '連結格式錯誤，請重新輸入', trigger: 'blur' }
  ],
  startedAt: [
    { required: true, message: '請設定活動發佈時間', trigger: 'blur' }
  ]
};

const LINK_TYPE_TRANSLATE = {
  [ELinkType.NONE]: '無',
  [ELinkType.REDIRECT]: '另開分頁',
  [ELinkType.ENROLL]: '報名活動'
};

export default defineComponent({
  props: {
    mode: {
      type: String as PropType<Mode>,
      required: true
    },
    sectionList: {
      type: Array as PropType<EventSection[]>,
      required: true
    }
  },
  components: {
    SectionLayout,
    Tinymce,
    SortableSectionTable
  },
  emits: ['update:sectionList', 'change'],
  setup(props, { emit }) {
    const { sectionList, mode } = toRefs(props);
    const eventId = useRoute().params.id as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref(cloneDeep(DEFAULT_FORM));

    const syncSectionList = computed<EventSection[]>({
      get() {
        return sectionList.value || [];
      },
      set(val) {
        emit('update:sectionList', val);
      }
    });

    // #region Get Event API 處理（編輯模式）
    const { data: event, isLoading: isEventLoading } = useEvent({ id: eventId });

    watch(event, (newEvent) => {
      if (mode.value === 'edit' && newEvent && newEvent.data) {
        formValues.value = {
          ...formValues.value,
          ...pick(newEvent.data, ['name', 'slug', 'seoTitle', 'seoKeywords', 'seoDescription', 'emailNotification', 'startedAt', 'enrollEndedAt', 'endedAt'])
        } as CreateEventData;

        syncSectionList.value = get(newEvent, 'data.eventSections', []) as EventSection[];
      }
    }, { immediate: true });
    // #endregion

    // #region Event-section 處理
    const isSectionSorting = ref(false);
    const sectionSortColumns = [
      {
        label: '版位名稱',
        key: 'name',
        align: 'center',
        content: {
          type: 'text',
          value: (row) => row.name || '-'
        }
      },
      {
        label: '區塊點擊類別',
        key: 'linkType',
        align: 'center',
        content: {
          type: 'text',
          value: (row) => LINK_TYPE_TRANSLATE[row.linkType] || '-'
        }
      }
    ];

    const handleSectionDelete = (section: EventSection) => {
      ElMessageBox.confirm('是否確認要刪除？', '警告',
        {
          confirmButtonText: '刪除',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          syncSectionList.value =
            filter(syncSectionList.value, (item) => item.id !== section.id);
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消刪除'
          });
        });
    };
    // #endregion

    // #region 表單提交
    const { isLoading: isCreateLoading, mutate: createEvent } = useCreateEvent();
    const { isLoading: isUpdateLoading, mutate: updateEvent } = useUpdateEvent();

    const submitForm = async() => {
      if (!formRef.value) return;
      await formRef.value.validate(async(valid, fields) => {
        if (valid) {
          const data = {
            ...formValues.value,
            eventSections: syncSectionList.value
          };

          if (mode.value === 'create') {
            createEvent({ data }, {
              onSuccess: () => {
                ElMessage.success('活動新增成功');
                router.push('/events');
              },
              onError: (error: ResponseError) => {
                ElMessage.error(error.response?.data.message);
              }
            });
          } else {
            updateEvent({ data, id: eventId }, {
              onSuccess: () => {
                ElMessage.success('活動更新成功');
                router.push('/events');
              },
              onError: (error: ResponseError) => {
                ElMessage.error(error.response?.data.message);
              }
            });
          }
        } else {
          ElMessage.error('表單驗證失敗');
        }
      });
    };
    // #endregion

    // #region 禁用日期
    const disabledStartedAt = (datesOnCalendar: any): boolean => {
      const endDateTimestamp = dayjs(formValues.value.enrollEndedAt).valueOf();
      const datesTimestamp = datesOnCalendar.getTime();
      const afterEndDate = datesTimestamp > endDateTimestamp;

      return afterEndDate;
    };

    const disabledEnrollEndedAt = (datesOnCalendar: any): boolean => {
      const startDateTimestamp = dayjs(formValues.value.startedAt).valueOf();
      const endDateTimestamp = dayjs(formValues.value.endedAt).valueOf();
      const datesTimestamp = datesOnCalendar.getTime();
      const beforeStartDate = datesTimestamp < startDateTimestamp;
      const afterEndDate = datesTimestamp > endDateTimestamp;

      return beforeStartDate || afterEndDate;
    };

    const disabledEndedAt = (datesOnCalendar: any): boolean => {
      const startDateTimestamp = dayjs(formValues.value.enrollEndedAt).valueOf();
      const datesTimestamp = datesOnCalendar.getTime();
      const beforeStartDate = datesTimestamp < startDateTimestamp;

      return beforeStartDate;
    };
    // #endregion

    const handlePreview = () => {
      if (isEmpty(syncSectionList.value)) {
        ElMessage.warning('目前沒有版位，預覽會是空白頁面');
        return;
      }

      localStorage.setItem(`eventPreview${eventId || 0}`, JSON.stringify({
        ...formValues.value,
        eventSections: syncSectionList.value
      }));
      window.open(`/#/events/${eventId || 0}/preview`);
    };

    return {
      LINK_TYPE_TRANSLATE,
      eventId,
      rules,
      formRef,
      formValues,
      syncSectionList,
      isEventLoading,
      handlePreview,

      isSectionSorting,
      sectionSortColumns,
      handleSectionDelete,

      isCreateLoading,
      isUpdateLoading,
      submitForm,

      disabledStartedAt,
      disabledEnrollEndedAt,
      disabledEndedAt
    };
  }
});
