
import ImageUploader, { UploadImageEvent } from '@/components/ImageUploader-v2.vue';
import { cloneDeep, debounce, find, findIndex, isEqual } from 'lodash';
import { ElMessage, ElForm } from 'element-plus';
import { PropType, computed, defineComponent, onMounted, ref, toRefs, unref } from 'vue';
import { EventSection } from '@/services/api';
import { ELinkType } from '@/interfaces';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_FORM: EventSection = {
  id: undefined,
  photoId: undefined,
  photo: undefined,
  mobilePhotoId: undefined,
  mobilePhoto: undefined,
  name: '',
  linkType: ELinkType.NONE,
  linkUrl: ''
};

export default defineComponent({
  components: {
    ImageUploader
  },
  props: {
    sectionList: {
      type: Array as PropType<EventSection[]>,
      required: true
    },
    initSection: {
      type: Object as PropType<EventSection>,
      default: () => cloneDeep(DEFAULT_FORM)
    }
  },
  emits: ['update:sectionList', 'cancel'],
  setup(props, { emit }) {
    const { sectionList, initSection } = toRefs(props);
    const formRef = ref<typeof ElForm>();
    const formValues = ref(cloneDeep(DEFAULT_FORM));

    const syncSectionList = computed({
      get() {
        return sectionList.value || [];
      },
      set(val) {
        emit('update:sectionList', val);
      }
    });

    const options = [
      {
        value: ELinkType.NONE,
        label: '無'
      },
      {
        value: ELinkType.REDIRECT,
        label: 'URL'
      },
      {
        value: ELinkType.ENROLL,
        label: '報名活動'
      }
    ];

    const rules = computed(() => {
      const defaultRules = {
        name: [
          { required: true, message: '請輸入版位名稱', trigger: 'blur' }
        ],
        photoId: [
          { required: true, message: '請設定版位圖片', trigger: 'blur' }
        ]
      };

      return formValues.value.linkType === ELinkType.REDIRECT
        ? {
          ...defaultRules,
          linkUrl: [
            { required: true, message: '請輸入版位連結', trigger: 'blur' },
            { pattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,22}(:[0-9]{1,5})?(\/.*)?$/, message: '請輸入正確網址格式', trigger: 'blur' }
          ]
        }
        : defaultRules;
    });

    const handleImageCrop = (image: UploadImageEvent, field: string) => {
      formValues.value[field] = image;
      formValues.value[field + 'Id'] = image.id;
      formRef.value.validateField(field + 'Id');
    };

    const handleCancel = () => {
      formValues.value = cloneDeep(DEFAULT_FORM);
      emit('cancel');
    };

    const isSubmitDisabled = ref(false);

    const submitForm = debounce(async() => {
      if (!formRef.value) return;
      await formRef.value.validate((valid) => {
        if (valid) {
          const index = findIndex(
            syncSectionList.value as EventSection[], ['id', formValues.value.id]);

          if (index >= 0) {
            syncSectionList.value[index] = formValues.value;
            ElMessage.success('版位更新成功');
          } else {
            syncSectionList.value = [
              {
                ...formValues.value,
                id: uuidv4()
              },
              ...syncSectionList.value as EventSection[]
            ];
            ElMessage.success('版位新增成功');
          }
          handleCancel();
        } else {
          ElMessage.error('表單驗證失敗');
        }
      });
    }, 200);

    onMounted(() => {
      formValues.value = cloneDeep(initSection.value);
      formRef.value.resetFields();
    });

    return {
      rules,
      formRef,
      formValues,
      options,
      isSubmitDisabled,
      submitForm,
      handleCancel,
      handleImageCrop
    };
  }
});
