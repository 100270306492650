import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eed6e07a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-8" }
const _hoisted_3 = { class: "table table-striped" }
const _hoisted_4 = { class: "thead-dark" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_draggable = _resolveComponent("draggable")!
  const _component_rawDisplayer = _resolveComponent("rawDisplayer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", _hoisted_3, [
        _createElementVNode("thead", _hoisted_4, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
              return (_openBlock(), _createElementBlock("th", {
                scope: "col",
                key: `column-${_ctx.get(column, 'key')}`
              }, _toDisplayString(_ctx.get(column, 'label')), 1))
            }), 128))
          ])
        ]),
        _createVNode(_component_draggable, {
          modelValue: _ctx.list,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
          tag: "tbody",
          "item-key": "id"
        }, {
          item: _withCtx(({element}) => [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
                return (_openBlock(), _createElementBlock("td", {
                  scope: "row",
                  key: `row-${_ctx.get(column, 'key')}`
                }, [
                  (_ctx.get(column, 'content.type') === 'text')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.get(column, 'content.value')(element)), 1))
                    : _createCommentVNode("", true),
                  (_ctx.get(column, 'content.type') === 'image')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        ( _ctx.get(column, 'content.value')(element))
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src:  _ctx.get(column, 'content.value')(element),
                              style: {"width":"150px"}
                            }, null, 8, _hoisted_6))
                          : (_openBlock(), _createElementBlock("span", _hoisted_7, "-"))
                      ], 64))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _createVNode(_component_rawDisplayer, {
      class: "col-3",
      value: _ctx.list,
      title: "List"
    }, null, 8, ["value"])
  ]))
}