

import { defineComponent, ref } from 'vue';
import EventDetail from './components/EventDetail.vue';
import SectionDetail from './components/SectionDetail.vue';
import { EventSection } from '@/services/api';

export default defineComponent({
  components: {
    EventDetail,
    SectionDetail
  },
  setup() {
    const stage = ref<'event' | 'section'>('event');
    const currentSection = ref<EventSection>();
    const sectionList = ref<EventSection[]>([]);

    const handleDetailChange = (data?: EventSection) => {
      currentSection.value = data;
      stage.value = 'section';
    };

    return {
      stage,
      sectionList,
      currentSection,
      handleDetailChange
    };
  }
});
