import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a9a47842"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "components_section-layout" }
const _hoisted_2 = {
  key: 0,
  class: "components_section-layout__title"
}
const _hoisted_3 = { class: "pretty p-svg p-plain" }
const _hoisted_4 = { class: "state" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "components_section-layout__title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.checkboxTitle)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.syncCheckboxModel) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.syncCheckboxModel]
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                class: "svg",
                src: require('@/assets/images/member/icon-check.svg')
              }, null, 8, _hoisted_5),
              _createElementVNode("label", null, _toDisplayString(_ctx.title), 1)
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("h2", _hoisted_6, _toDisplayString(_ctx.title), 1)),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}