import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { DEFAULT_PAGE_SIZE } from '@/services/api';

const DEFAULT_PAGE = 1;

export function usePage(
  initialPage: number = DEFAULT_PAGE,
  size: number = DEFAULT_PAGE_SIZE
) {
  const router = useRouter();
  const route = useRoute();

  const page = ref(initialPage);
  const pageSize = ref(size);

  // TODO: no need this function, just mutate page outside
  const setPageQuery = (newPage: number) => {
    page.value = newPage;
  };

  watch(page, (newPage) => {
    router.push({ query: { ...route.query, page: newPage } });
  });

  watch(() => route.query.page, (newPage) => {
    page.value = Number(newPage) || DEFAULT_PAGE;
  }, { immediate: true });

  return {
    page,
    pageSize,
    setPageQuery
  };
}
